<template>

    <div>
  
      <template>
        <div id="breadcrumb-alignment" class="mb-1">
          <div class="d-flex justify-content-start breadcrumb-wrapper">
            <b-breadcrumb
              :items="breadCrumb()"
            />
          </div>
        </div>
      </template>
  
      <b-card no-body v-if="checkPermission($route.name, 'View')">
      
        <b-card-body>
          <b-row>
            <b-col lg="9">
                <b-row>  
                    <b-col cols="12" md="6" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five pb-1">
                      <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="sites"
                        label="site_name"
                        class="w-100"
                        placeholder="Project Site"
                        v-model="siteData"
                        @input="filterTable(); allLocations(); location_list = []; location = null;"
                        :clearable="false"
                      >
                        <!-- <template #selected-option="{ site_name }">
                          <span v-if="site_name.length < 18">{{site_name}}</span>
                          <span v-if="site_name.length >= 18">{{site_name.substring(0, 18) + '..'}}</span>
                        </template> -->
                      </v-select>
                    </b-col>

                    <b-col cols="12" md="6" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five pb-1">
                        <v-select
                            v-model="location"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options="location_list"
                            placeholder="Select Locations"
                            @input="filterTable"
                            :clearable="false"
                        />
                    </b-col>

                    <b-col cols="12" md="6" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
                        
                        <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="types"
                        class="w-100"
                        placeholder="Type"
                        v-model="type"
                        @input="filterTable"
                        :clearable="false"
                        />
                    </b-col>

                    <b-col cols="12" md="6" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
                        <div class="d-flex align-items-center">   
                            <flat-pickr
                                v-model="date_range"
                                :config="date_config"
                                class="form-control flat-picker"
                                placeholder="Select Date Range"
                                @on-change="setDate()"
                            />

                        </div>
                    </b-col>
                </b-row>
            </b-col>

            <b-col lg="3">
                <b-row>
                    <b-col cols="6">

                        <div class="text-center pt-2" style="font-size: 20px;" >
                            <!-- <feather-icon icon="SmileIcon" class="text-success" style="height: 34px; width: 34px;" />  -->
                            <img :src="satisfied == true ? happy_face_animate : happy_face" style="width:40px; height:auto;" :class="satisfied == true ? 'border-warning-c rounded' : ''" @click="filterSatisfied('satisfied')" />
                            <b-badge variant="success" class="ml-1">
                              <span>{{happy}}</span>
                            </b-badge>
                        </div>
                    </b-col>
                    <b-col cols="6">
                        <div class="text-center pt-2" style="font-size: 20px;" >
                            <!-- <feather-icon icon="FrownIcon" class="text-danger" style="height: 34px; width: 34px;" />  -->
                            <img :src="un_satisfied == true ? unhappy_face_animate : unhappy_face" style="width:40px; height:auto;" :class="un_satisfied == true ? 'border-warning-c rounded' : ''" @click="filterSatisfied('un_satisfied')" />
                            <b-badge variant="danger" class="ml-1">
                              <span>{{unHappy}}</span>
                            </b-badge>
                        </div>
                    </b-col>
                    <!-- <b-col cols="4">
                        <div class="text-center h1 text-secondary">
                            <feather-icon icon="PlusSquareIcon" class="text-primary" style="height: 34px; width: 34px;" /> {{ unHappy + happy }}
                        </div>
                    </b-col> -->
                </b-row>
            </b-col> 

          </b-row>
          
        </b-card-body>
      </b-card>

      <!-- <b-row class="match-height">      
        <b-col md="4">
          <statistic-card-horizontal color="success" statistic="Happy" :count="happy" />
        </b-col>

        <b-col md="4">
          <statistic-card-horizontal color="danger" statistic="Unhappy" :count="unHappy" />
        </b-col>        

        <b-col md="4">
          <statistic-card-horizontal color="primary" statistic="Total" :count="happy+unHappy" />
        </b-col>        
      </b-row> -->
  
      <b-card 
        no-body
        class="mb-0"
        v-if="checkPermission($route.name, 'View')"
      >
  
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
  
            <!-- Per Page -->
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem"
            >
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @input="filterTable"
              />
              <label>entries</label>
            </b-col>
  
            <!-- Search -->
            <b-col
              cols="12"
              md="9"
              class="mobile_tab_max_width_flex all_btn_tab text-align-end"
            >
            
             <b-button
              :disabled="(siteData != null && siteData._id != 'all-site' && items.length > 0)  ? false:true"
                variant="success"
                class="mobile-margin-bottom"
                @click="download()"
            >
                <span class="text-nowrap">PDF</span>
            </b-button>
              <!-- <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem "
                  placeholder="Search..."
                  @input="filterTable"
                />
  
  
  
  
                <b-button
                  v-if="checkPermission($route.name, 'Add')"
                  variant="primary"
                  :to="{name:'add-adhoc-task'}"
                  class="mr-1 mobile-margin-bottom"
                >
                  <span class="text-nowrap">Add</span>
                </b-button>
              </div> -->
            </b-col>
          </b-row>
  
        </div>
        <b-table sticky-header
          ref="refUserListTable"
          class="position-relative maxHeight common_big_tbl"
          :items="dataList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
        >
          <template #cell(site)="items">             
            <span class="wordBreak">
              <div class="my-1">{{ items.item.site.site_name | capitalize}}</div>
            </span>
          </template>
          
          <template #cell(location)="items">
            <span class="wordBreak">{{ items.item.location.name | capitalize}}</span>
          </template>
          
          <template #cell(feedback_title)="items">
            <span v-if="items.item.category_name == 'happy'" class="wordBreak">Happy Feedback</span>
            <span v-else class="wordBreak"> {{items.item.task_id.title | capitalize}} </span>
          </template>

          <template #cell(category_name)="items">
            <span v-if="items.item.category_name == 'happy'" class="wordBreak">Satisfied</span>
            <span v-else class="wordBreak"> Unsatisfied </span>
          </template>
  
          <!-- <template #cell(feedback)="items">             
              <span class="wordBreak" v-if="items.item.feedback_from == 'web'">{{ 'QR Code'  }}</span>
              <span class="wordBreak" v-else-if="items.item.feedback_from == 'app'">{{ 'Feedback Panel'  }}</span>
              <span class="wordBreak" v-else>{{ 'N/A'  }}</span>
          </template> -->
  
          <template #cell(type)="items">             
            <span class="wordBreak">{{ items.item.type | capitalize}}</span>
          </template>
  
          <template #cell(actual_started)="items">
            <span v-if="items.item.category_name != 'happy'" class="wordBreak">{{ sitedateTime(items.item.task_id.actual_started, siteData.pdf_date_format,'' )}}</span>
            <span v-else> N/A </span>
          </template>
  
          <template #cell(actual_end)="items">
            <span v-if="items.item.category_name != 'happy'" class="wordBreak">{{ sitedateTime(items.item.task_id.actual_end, siteData.pdf_date_format,'' )}}</span>
            <span v-else> N/A </span>
          </template>
          
          <template #cell(total_time)="items">           
            <span v-if="items.item.category_name != 'happy'"> 
              <span v-if="items.item.task_id.total_time == null">
                  N/A
              </span>
              <span v-else class="wordBreak">
                  {{ items.item.task_id.total_time }}
              </span>
            </span>
            <span v-else> N/A </span>
          </template>

          <!-- <template #cell(created_at)="items">
            <span v-if="items.item.category_name != 'happy'">{{sitedateTime(items.item.task_id.created_at, siteData.pdf_date_format,siteData.pdf_time_format)}}</span>
            <span v-else>
              {{sitedateTime(items.item.date, siteData.pdf_date_format,'')}}
            </span>
          </template> -->
          <template #cell(created_at)="items">
            <span> {{ sitedateTime(items.item.created_at, siteData.pdf_date_format,siteData.pdf_time_format) }} </span>
          </template>
  
          <template #cell(task_status)="items">             
            <b-badge pill :variant="variantProgressColor(items.item.task_status)" class="text-capitalize">
              {{ items.item.task_status | capitalize}}
            </b-badge>           
          </template>
  
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
  
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
  
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRecords"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="filterTable"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
  
            </b-col>
  
          </b-row>
        </div>
      </b-card>
  
    </div>
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,BCardBody,BFormCheckbox,BAlert,BForm,BBreadcrumb
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { POST_API } from "../../../store/actions.type"
  import moment from "moment-timezone";
  import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
  import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

  
import flatPickr from 'vue-flatpickr-component';
  
  export default {
    components: {
      BCardBody,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BFormCheckbox,
      vSelect,
      BAlert,
      BForm,
      BBreadcrumb,
      flatPickr,
      StatisticCardVertical,
      StatisticCardHorizontal
    },
    data() {
      return {
         tableColumns: [
           // { key: 'feedback_from', label: 'feedback_from', sortable: false , thStyle:  {width: '11%',fontSize:'10px'}},
            { key: 'category_name', label: 'Feedback Type', sortable: false , thStyle:  {width: '11%',fontSize:'10px'}},
            { key: 'site', label: 'Project Site', sortable: false , thStyle:  {width: '12%',fontSize:'10px'}},
            { key: 'location', label: 'Location Name', sortable: false , thStyle:  {width: '16%',fontSize:'10px'}},
            { key: 'feedback_title', label: 'Feedback', sortable: false , thStyle:  {width: '17%',fontSize:'10px'}},
            { key: 'created_at', label: 'Trigger Time', sortable: false , thStyle:  {width: '11%',fontSize:'10px'}},
            { key: 'actual_started', label: 'Attend Time', sortable: false , thStyle:  {width: '11%',fontSize:'10px'}},
            { key: 'actual_end', label: 'Close Time', sortable: false , thStyle:  {width: '11%',fontSize:'10px'}},
            { key: 'total_time', label: 'Time Taken', sortable: false , thStyle:  {width: '11%',fontSize:'10px'}}
          ],
          
          items:[],
          isSortDirDesc: true,
          perPageOptions: [10, 20, 50, 100],
          perPage : 10,
          sortBy : 'id',
          webUrl: process.env.VUE_APP_SERVER_URL,
          totalRecords : 0,
          currentPage : 1,
          searchQuery:'',
          from:null,
          to:null,          
          
          sites:[],
          siteData:null,

          types: ['QR Code', 'Feedback Panel','Web', 'All'],
          type:'All',

        popshowDismissibleAlert:false,
        error_message:'',

        location_list: [],
        location: null,

        start:'',
        end:'',

        date_config:{ 
            mode: 'range',
            dateFormat: 'd M Y',
            defaultDate: [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
        },
        date_range: moment().startOf('month').format('DD MMM YYYY') + ' to ' + moment().format('DD MMM YYYY'),
        happy: 0,
        unHappy: 0,
        happy_face: '',
        unhappy_face : '', 
        happy_face_animate: require('@/assets/images/happy_animate.gif'),
        unhappy_face_animate: require('@/assets/images/unhappy_animate.gif'),
        satisfied : false,
        un_satisfied : false,
        user_experience : ''
      }
    },
    methods : {
       download(){

        console.log(429);
            var sortBy = this.sortBy;
            var sortDirection = this.isSortDirDesc;
            var site = this.siteData ? this.siteData._id : "";
            var site_name = this.siteData ? this.siteData.site_name : "";
            var location = this.location ? this.location._id : 'null';
            var location_name = this.location ? this.location.name : "All Locations";
            var type = this.type?this.type:null;
            var  user_experience = this.user_experience
        console.log(435);
            
            

         
        console.log(444, this.location);

           

            var role = this.$store.getters.currentUser.role;

           
            var start = this.start != null && this.start != '' ? moment(this.start).format('YYYY-MM-DD') : "";
            var end = this.end != null && this.end != '' ? moment(this.end).format('YYYY-MM-DD') : "";
        console.log(453);

            

            var params = "sortBy="+sortBy+"&sortDirection="+sortDirection+"&site="+site+"&site_name="+site_name+
            "&locations="+location+"&start="+start+"&location_name="+location_name+
            "&user_experience="+user_experience+"&type="+type+"&end="+end+"&organization="+ this.$store.getters.currentUser.organization+"&role="+role+
            "&user_id="+this.$store.getters.currentUser._id;

           

            var baseurl = process.env.VUE_APP_SERVER_URL +"/api/feedback-summary-pdf?"+params;

            window.open(baseurl,'_blank');
        },
      filterSatisfied(type){
        if(type == 'satisfied'){
          if(this.satisfied == true){
            this.satisfied = false
            this.user_experience = ''
          } else {
            this.satisfied = true;
            this.un_satisfied = false;
            this.user_experience = 'satisfied'
          }
        } else if (type == 'un_satisfied'){
          if(this.un_satisfied == true){
            this.un_satisfied = false;
            this.user_experience = ''
          } else {
            this.satisfied = false;
            this.un_satisfied = true;
            this.user_experience = 'un_satisfied'
          }
        }
        this.filterTable();
      },
      dataList(){
        return this.$store.dispatch(POST_API, {
                     data:{
                       page: this.currentPage,
                       keyword: this.searchQuery,
                       rowsPerPage:this.perPage,
                       sortBy:this.sortBy,
                       sortDirection:this.isSortDirDesc,
                       role:this.$store.getters.currentUser.role,
                       om_sites:this.$store.getters.currentUser.om_sites,
                       site:this.siteData ? this.siteData._id : null,
                       location:this.location ? this.location._id : null,
                       type:this.type?this.type:null,
                       start:this.start,
                       end:this.end,
                       user_experience:this.user_experience
                     },
                     api: '/api/feedback-summary'
                  })
                  .then(() => {
                      if (this.$store.getters.containsErrors) {
                          this.error_message = this.$store.getters.getErrors;
                      } else {
                          this.items = this.$store.getters.getResults.data.docs;
                          this.totalRecords = this.$store.getters.getResults.data.total;
                          this.perPage = this.$store.getters.getResults.data.limit;
                          this.from = this.$store.getters.getResults.data.from;
                          this.to = this.$store.getters.getResults.data.to;

                          this.happy = this.$store.getters.getResults.data.happy
                          this.unHappy = this.$store.getters.getResults.data.unHappy
                          return this.items;
                      }
                  });
      },
      filterTable(){
        this.$refs.refUserListTable.refresh();
        this.selected = [];
        this.selectAll = false;
      },
      variantColor(status){
        if (status == 'active') {
          return 'success';
        }else{
          return 'primary';
        }
      },
      variantProgressColor(status){
        if (status == 'open') {
          return 'primary';
        }else if(status == 'in_progress'){
          return 'warning'
        }else if(status == 'validate'){
          return 'info'
        }else if(status == 'failed'){
          return 'danger'
        }else{
          return 'success';
        }
      },
      
      allSites(){
        return this.$store.dispatch(POST_API, {
           data:{
             role:this.$store.getters.currentUser.role,
             om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.sites = this.$store.getters.getResults.data;
  
                var obj = {
                  _id:'all-site',
                  site_name:'All Project Sites',
                  pdf_date_format: 'DD MMM YYYY',
                  pdf_time_format: 'HH:mm'
                }
                this.sites.unshift(obj);
                
                this.siteData = this.defaultSite(this.sites);
                this.filterTable();

                if(this.siteData){
                    this.allLocations();
                }
  
                if (this.$route.params.site && this.$route.params.site != 'null') {
            
                    var site = this.$route.params.site;
                    var selectedSite = [];
                    
                    this.sites.forEach(function(item){
                        if (item._id == site) {
                           selectedSite.push(item);
                        }
                    })
                    this.siteData = selectedSite[0];
                }
  
                return this.sites;
            }
        });
      },
      
      getFaces(){
        return this.$store.dispatch(POST_API, {
           data:{
             alias:['happy_face', 'unhappy_face']
           },
           api: '/api/get-feedback-panel-config'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {                
                this.$store.getters.getResults.data.forEach((el) => {
                  if(el.alias == 'happy_face'){
                    this.happy_face = el.image;
                  }
                  if(el.alias == 'unhappy_face'){
                    this.unhappy_face = el.image;
                  }
                })
            }
        });
      },

        allLocations(){
            return this.$store.dispatch(POST_API, {
                data:{
                    site:this.siteData ? this.siteData._id : null,
                    type: 'toilet'
                },
                api: '/api/all-locations'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data  = this.$store.getters.getResults.data;
                    
                    this.location_list = data;

                    var obj = {
                        _id:null,
                        name:'All Locations'
                    }
                    this.location_list.unshift(obj);
                    
                    this.location = this.location_list[0]

                    return this.location_list;
                }
            });
        },

        setDate(){
			var date = this.date_range.split(' to ');

	        if (date[1]) {
	            this.start = moment(new Date(date[0])).format('YYYY-MM-DD');
	            this.end = moment(new Date(date[1])).format('YYYY-MM-DD');
	        }else{
	            this.start = moment(new Date(date[0])).format('YYYY-MM-DD');
	            this.end = moment(new Date(date[0])).format('YYYY-MM-DD');
	        }
            this.filterTable();
		},

        breadCrumb(){
            var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
            },{
                to:null,
                text: 'Feedback System',
            },{
                to:null,
                text: 'Reports',
            },{
                to:null,
                text:'Summary',
                active:true
            }];

            return item;
        },
    },
    mounted() {
      this.setDate();
      this.allSites();
      this.getFaces();
    }
    
  }
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  .wordBreak2{
    text-align: justify !important;
    text-justify: inter-word !important;
  }
  .border-warning-c {
    // border: 3px solid #d4af37 !important;
    background: #161d31;
    padding: 3px;
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
  